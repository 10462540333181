import { Text, type TextProps } from '@mantine/core';

type Props = {
  children: string
  highlightedTerm: string
  highlightedTextProps: TextProps
} & TextProps;

export const TextWithHighlightedTerm: React.FC<Props> = (props: Props) => {
  const {
    children,
    highlightedTerm,
    highlightedTextProps,
    ...textProps
  } = props;

  const highlightedTermIsEmpty = highlightedTerm.trim() === '';
  const splitText = highlightedTermIsEmpty ? [children] : children.split(new RegExp(`(${highlightedTerm})`, 'gi'));
  const textElements = splitText.map((textSegment) => (
    textSegment.toLowerCase() === highlightedTerm.toLowerCase()
      ? (
          <Text span key={textSegment} {...highlightedTextProps}>
            {textSegment}
          </Text>
        )
      : textSegment
  ));

  return (
    <Text {...textProps}>
      {textElements}
    </Text>
  );
};

export default TextWithHighlightedTerm;
