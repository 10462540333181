import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { isNotEmpty, matches, useForm } from '@mantine/form';
import {
  Title, Button, Input, Text, Group, Box, TextInput, LoadingOverlay, Select, Tooltip, ScrollArea
} from '@mantine/core';

import useEnsisQuery from '../../../hooks/useEnsisQuery';
import type { GetResponseDataType } from '../../../types/apiTypes';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { hasPermissionsForObject } from '../../../utils/apiUtils';
import { US_STATE_SELECT_OPTIONS } from '../../../utils/addressUtils';

interface FormValues {
  address1: string
  address2: string
  city: string
  state: string
  zipCode: string
  phone: string
}

const CompanyDetails: React.FC = () => {
  const { organization } = useParams();

  const form = useForm({
    initialValues: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      phone: ''
    },
    validate: {
      address1: isNotEmpty('Please input street address'),
      city: isNotEmpty(),
      state: isNotEmpty(),
      zipCode: isNotEmpty(),
      phone: matches(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Please input a valid phone number')
    }
  });

  const onGetOrgDataSuccess = useCallback(
    (data: GetResponseDataType<typeof orgQueryRoute>) => {
      form.setInitialValues({
        address1: data.address?.address_1 ?? '',
        address2: data.address?.address_2 ?? '',
        city: data.address?.city ?? '',
        state: data.address?.state ?? '',
        zipCode: data.address?.zip_code ?? '',
        phone: data.phone ?? ''
      });
      form.reset();
    },
    []
  );

  const orgQueryRoute = `/app/organizations/${organization}/data` as const;
  const { data, isLoading } = useEnsisQuery(
    orgQueryRoute,
    { onSuccess: onGetOrgDataSuccess }
  );

  const editOrgMutation = useEnsisMutation(
    `/app/organizations/${organization}/data`,
    {
      contentType: 'application/json',
      requestType: 'patch',
      successMessage: 'Organization info updated',
      queryKeysToInvalidate: [orgQueryRoute],
      onSuccess: onGetOrgDataSuccess
    }
  );

  const onSubmit = useCallback((values: FormValues) => {
    editOrgMutation.mutate({
      address: {
        address_1: values.address1,
        address_2: values.address2,
        city: values.city,
        state: values.state,
        zip_code: values.zipCode
      },
      phone: values.phone
    });
  }, [editOrgMutation.mutate]);

  const canEditOrg = hasPermissionsForObject(data, 'change');

  return (
    <ScrollArea w={650} type='hover' style={{ height: 'calc(100vh - 250px)' }} >
      <LoadingOverlay visible={isLoading} />
      <Box mb={16}>
        <Title order={6}>
          Basic Information
        </Title>
        {!canEditOrg && <Text>  Contact your company admin to request changes to this information </Text>}
      </Box>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Tooltip
          events={{
            hover: true,
            focus: false,
            touch: false
          }}
          label='Need to edit? Contact us at info@ensis.ai'
        >
          <TextInput
            placeholder='Company Name'
            label='Company Name'
            disabled
            w={548}
            mb={16}
            value={data?.name}
          />
        </Tooltip>
        <TextInput
          placeholder='Company Business Address'
          label='Street Address'
          disabled={!canEditOrg}
          w={548}
          mb={16}
          {...form.getInputProps('address1')}
        />
        <TextInput
          placeholder='Company Suite #'
          label='Unit, suite, etc'
          w={548}
          mb={16}
          disabled={!canEditOrg}
          {...form.getInputProps('address2')}
        />
        <Group>
          <TextInput
            label='City'
            w={329}
            mb={16}
            disabled={!canEditOrg}
            {...form.getInputProps('city')}
          />
          <Select
            label='State'
            allowDeselect={false}
            w={116}
            mb={16}
            styles={{
              dropdown: {
                borderRadius: 4
              }
            }}
            radius={4}
            data={US_STATE_SELECT_OPTIONS}
            disabled={!canEditOrg}
            {...form.getInputProps('state')}
          />
        </Group>
        <TextInput
          label='Postal Code'
          w={548}
          mb={16}
          disabled={!canEditOrg}
          {...form.getInputProps('zipCode')}
        />
        <Input.Wrapper label='Phone Number' mb={16}>
          <Group>
            <Text inline>
              +1
            </Text>
            <Input
              placeholder='Phone Number'
              disabled={!canEditOrg}
              {...form.getInputProps('phone')}
            />
          </Group>
        </Input.Wrapper>

        {canEditOrg && (
          <Button type="submit" w={138} loading={editOrgMutation.isPending} disabled={!form.isDirty()}>
            Save profile
          </Button>
        )}
      </form>
    </ScrollArea>);
};

export default CompanyDetails;
