import { useNavigate, useParams } from 'react-router-dom';

import { useCallback, useEffect, useState } from 'react';

import { Box, Modal, Tabs } from '@mantine/core';

import { useDisclosure } from '@mantine/hooks';

import useEnsisQuery from '../../hooks/useEnsisQuery';
import { defaultModalProps, showFailureNotification } from '../../utils/mantineUtils';
import ProcessingScreen from './ProcessingScreen';
import { CenteredLoader } from '../../components';

import ProposalOutlineWithDocumentViewer from '../../components/ProposalOutlineWithDocumentViewer';
import RequirementsListByFile from './RequirementsListByFile';
import { formatSectionsMetadata } from '../../utils/apiUtils';
import { MoveProposalToAuthoring } from '../../components/Modals';
import { ProposalStatusType } from '../../utils/constants';
import useEnsisMutation from '../../hooks/useEnsisMutation';

const DATA_REFETCH_INTERVAL_MS = 10000;

const SetupProposal: React.FC = () => {
  const { proposalUid, organization } = useParams();

  const [refetchingEnabled, setRefetchingEnabled] = useState(true);
  const { data: proposal, isLoading: proposalLoading } = useEnsisQuery(
    `/app/proposals/${proposalUid}/data`, {
      refetchInterval: refetchingEnabled ? DATA_REFETCH_INTERVAL_MS : undefined
    }
  );
  const updateProposalStatusMutation = useEnsisMutation(`/app/proposals/${proposalUid}/data`,
    {
      requestType: 'patch',
      successMessage: 'Proposal status updated',
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    });

  const [modalOpened, modalHandlers] = useDisclosure();
  const navigate = useNavigate();
  const isProcessing = proposal?.opportunity?.processing_status !== 'COMPLETE';

  const isLoading = (
    proposalLoading ||
    proposal === undefined
  );

  const handleModalOnContinue = useCallback(() => {
    updateProposalStatusMutation.mutate({
      status: ProposalStatusType.AUTHORING
    }, {
      onSuccess: () => { navigate(`/org/${organization}/proposals/${proposalUid}`); }
    });
  }, [updateProposalStatusMutation]);

  useEffect(() => {
    if (proposal?.opportunity?.processing_status === 'FAILED') {
      showFailureNotification('Proposal processing failed. Check your email for more information.');
      navigate(`/org/${organization}/proposals`);
    }
    if (proposal?.opportunity?.processing_status === 'PROCESSING') {
      setRefetchingEnabled(true);
    } else {
      setRefetchingEnabled(false);
    }
  }, [proposal?.opportunity?.processing_status]);

  const formattedSections = formatSectionsMetadata(proposal?.sections ?? []);
  if (isProcessing) {
    return <ProcessingScreen />;
  };

  if (isLoading) {
    return <CenteredLoader h='calc(100vh - 300px)' w='100%' />;
  };

  return (
    <Box ta='start' w={'100vw'}>
      <Modal opened={modalOpened} {...defaultModalProps}>
        <MoveProposalToAuthoring
          proposalUid={proposalUid}
          close={modalHandlers.close}
          onContinue={handleModalOnContinue}
        />
      </Modal>
      <Tabs w='100%' defaultValue='requirements'>
        <Tabs.List w='100%' mih={46}>
          <Tabs.Tab value='requirements'>
            Requirements
          </Tabs.Tab>
          <Tabs.Tab value='outline'>
            Outline
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="requirements">
          <RequirementsListByFile
            sections={formattedSections}
            onStartAuthoring={modalHandlers.open}
            proposal={proposal}
            opportunityUid={proposal.opportunity.uid}
          />
        </Tabs.Panel>
        <Tabs.Panel value="outline">
          <ProposalOutlineWithDocumentViewer
            isSetup
            requirementRemovalType="DELETE"
            sections={formattedSections}
            proposal={proposal}
            style={{ height: 'calc(100vh - 170px)' }}
            onStartAuthoring={modalHandlers.open}
          />
        </Tabs.Panel>
      </Tabs>
    </Box>);
};

export default SetupProposal;
