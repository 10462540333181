import { Button, Group, Space, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import { type Proposal } from '../../types/apiTypes';
import { WIN_THEMES_CONTEXT_HELPER_TEXT, WIN_THEMES_CONTEXT_TEXT } from '../../utils/constants';
import { PreventNavigationWarning } from '../../components/Modals';

interface Props {
  additionalContext?: string | null
  tabIsFocused: boolean
}

export const AdditionalProposalInfo: React.FC<Props> = (props: Props) => {
  const { proposalUid } = useParams();
  const { additionalContext, tabIsFocused } = props;
  const form = useForm({
    initialValues: {
      text: additionalContext ?? ''
    }
  });

  const onMutationSuccess = useCallback(
    (data: Proposal) => {
      form.setInitialValues({
        text: data.additional_context ?? ''
      });
      form.reset();
    },
    []
  );

  const editProposalAdditionalInfoMutation = useEnsisMutation(`/app/proposals/${proposalUid}/data`, {
    requestType: 'patch',
    successMessage: 'Additional information updated',
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`],
    onSuccess: onMutationSuccess
  });

  const onSubmit = useCallback((values: typeof form.values) => {
    editProposalAdditionalInfoMutation.mutate({
      additional_context: values.text
    });
  }, [editProposalAdditionalInfoMutation.mutate]);

  const shouldPreventNav = tabIsFocused && form.isDirty();

  const preventNavigationWarning = (
    <PreventNavigationWarning
      title="Leave this page?"
      shouldPreventNavigation={shouldPreventNav}
      shouldPreventRefresh={shouldPreventNav}
    >
      {'You made changes that haven\'t been saved. Are you sure you want to leave?'}
      <Space h='md'/>
      Save before you go!
    </PreventNavigationWarning>
  );

  return (
    <>
      { preventNavigationWarning }
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Text ta='start'> {WIN_THEMES_CONTEXT_TEXT} </Text>
        <Textarea
          mt={12}
          autosize
          placeholder={WIN_THEMES_CONTEXT_HELPER_TEXT}
          minRows={4}
          {...form.getInputProps('text')}
        />
        <Group justify='right' mt={32}>
          <Button
            type='submit'
            disabled={!form.isDirty() || editProposalAdditionalInfoMutation.isPending}
          >
            Save
          </Button>
        </Group>
      </form>
    </>
  );
};
