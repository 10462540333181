import { Button, Chip, Text, Group, Popover, ScrollArea } from '@mantine/core';
import React, { useCallback } from 'react';

import { FilterOutline, FilterFilled } from '../../icons';
import { type OrganizationResourceTag } from '../../types/apiTypes';
import { RESOURCE_TYPE_TO_STRING_MAP } from '../../utils/constants';
import { type ResourceType, type ResourceFilterStateNoSearch } from '../../types/miscTypes';

const DEFAULT_FILTERS: ResourceFilterStateNoSearch = {
  tags: [],
  resourceTypes: [],
  inUse: false
};

export interface Props {
  filters: ResourceFilterStateNoSearch
  onUpdateFilters: (newFilters: ResourceFilterStateNoSearch) => void
  availableTags: OrganizationResourceTag[]
  canFilterByInUse?: boolean
}

const ResourceFilter: React.FC<Props> = (props: Props) => {
  const {
    filters,
    onUpdateFilters,
    availableTags,
    canFilterByInUse = false
  } = props;

  const { tags, resourceTypes, inUse } = filters;

  const hasSelectedFilters = filters.tags.length > 0 || filters.resourceTypes.length > 0 || inUse;

  const handleUpdateTags = useCallback((newTags: string[]) => {
    onUpdateFilters({ ...filters, tags: newTags });
  }, [filters, onUpdateFilters]);

  const handleUpdateResourceTypes = useCallback((resourceTypes: ResourceType[]) => {
    onUpdateFilters({ ...filters, resourceTypes });
  }, [filters, onUpdateFilters]);

  const handleUpdateInUse = useCallback((inUse: boolean) => {
    onUpdateFilters({ ...filters, inUse });
  }, [filters, onUpdateFilters]);

  const inUseChip = (
    <Chip
      key={'inUse'}
      variant='outline'
      checked={inUse}
      onClick={() => {
        if (canFilterByInUse) {
          handleUpdateInUse(!inUse);
        }
      }}
    >
      Yes
    </Chip>
  );

  const resourceTypeChips = Object.keys(RESOURCE_TYPE_TO_STRING_MAP).map((type) => {
    const isChecked = resourceTypes.includes(type as ResourceType);
    const updatedTypes = isChecked
      ? resourceTypes.filter((t) => t !== type)
      : resourceTypes.concat(type as ResourceType);
    return (
      <Chip
        key={type}
        variant='outline'
        checked={isChecked}
        onClick={() => {
          handleUpdateResourceTypes(updatedTypes);
        }}
      >
        {RESOURCE_TYPE_TO_STRING_MAP[type as ResourceType]}
      </Chip>
    );
  });

  const handleResetFilters = useCallback(() => {
    onUpdateFilters(DEFAULT_FILTERS);
  }, [onUpdateFilters]);

  const resourceTagChips = availableTags.map((tag) => {
    const isChecked = tags.includes(tag.text ?? '');
    const updatedTags = isChecked
      ? tags.filter(t => t !== tag.text)
      : tags.concat(tag.text ?? '');
    return (
      <Chip
        key={tag.uid}
        variant='outline'
        checked={isChecked}
        onClick={() => {
          handleUpdateTags(updatedTags);
        }}
      >
        <Text truncate="end" maw={272} fz={14} styles={{
          root: {
            color: 'var(--mantine-color-lightPurple-5)'
          }
        }}>
          {tag.text}
        </Text>
      </Chip>
    );
  });

  const getFilterTypeText = () => {
    if (resourceTypes.length > 0) {
      return (
        <Text
          span
          fw={600}
          fz='sm'
        >
          Type <Text span fz='sm'> {resourceTypes.map(type => RESOURCE_TYPE_TO_STRING_MAP[type]).join(', ')} </Text>
        </Text>
      );
    }
  };

  const getFilterInUseText = () => {
    if (inUse) {
      return (
        <Text
          span
          fw={600}
          fz='sm'
        >
          In Use <Text span fz='sm'> Yes </Text>
        </Text>
      );
    }
  };
  const getFilterTagsText = () => {
    if (tags.length > 0) {
      return (
        <Text
          span
          fw={600}
          fz='sm'
        >
          Tags <Text span fz='sm'> {tags.join(', ')} </Text>
        </Text>
      );
    }
  };

  const getFilterText = () => {
    if (tags.length === 0 && resourceTypes.length === 0 && !inUse) {
      return <Text fz='sm' fw={600}> Filter </Text>;
    }
    return (
      <Text fz='sm' fw={600} truncate>
        Filter: {getFilterInUseText()} {getFilterTypeText()} {getFilterTagsText()}
      </Text>
    );
  };

  const defaultFilterButtonStyle = {
    border: 'none',
    color: 'black'
  };

  return (
    <Group justify='flex-start' pb={16} pt={16}>
      <Popover
        width={400}
        shadow='md'
        position='bottom-start'
      >
        <Popover.Target>
          <Button
            maw={400}
            style={[{
              alignSelf: 'flex-start'
            }, !hasSelectedFilters ? defaultFilterButtonStyle : {}]}
            pl={16}
            leftSection={!hasSelectedFilters ? <FilterOutline /> : <FilterFilled />}
            variant='outline'
          >
            {getFilterText()}
          </Button>
        </Popover.Target>
        {hasSelectedFilters && <Button
          variant='outline'
          fw='bold'
          style={{
            border: 'none'
          }}
          onClick={handleResetFilters}
        >
          Clear Filters
        </Button>}
        <Popover.Dropdown>
         { canFilterByInUse &&
         <>
          <Text fz='sm' mb={8} fw={600}>
            In Use
          </Text>
          {inUseChip}
          </>
          }
          <Text fz='sm' mt={canFilterByInUse ? 16 : 8} mb={8} fw={600}>
            Types
          </Text>
          <Chip.Group multiple>
            <Group pb={8} pr={14} pl={14}>
              {resourceTypeChips}
            </Group>
          </Chip.Group>
          <Text fz='sm' fw={600} mt={8}>
            Tags
          </Text>
          <ScrollArea.Autosize mah='400px'>
            <Chip.Group multiple>
              <Group pt={8} pr={14} pl={14}>
                  {resourceTagChips}
              </Group>
            </Chip.Group>
          </ScrollArea.Autosize>
        </Popover.Dropdown>
      </Popover>
    </Group>
  );
};

export default ResourceFilter;
