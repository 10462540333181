import { useCallback, useEffect } from 'react';

import { Box, Text, Title } from '@mantine/core';

import { useDispatch } from 'react-redux';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import ModalContent from '../ModalContent';
import { updateOutlineRequirements } from '../../redux/OutlineSlice';

import useEnsisQuery from '../../hooks/useEnsisQuery';
import { formatRequirementData } from '../../utils/requirementUtils';
import CenteredLoader from '../CenteredLoader';
import { UNASSIGNED_SECTION_DATA } from '../../utils/constants';
import { showWarningNotification } from '../../utils/mantineUtils';

interface Props {
  proposalUid: string
  onClose: () => void
}
const AutosortRequirements: React.FC<Props> = (props: Props) => {
  const { proposalUid, onClose } = props;
  const dispatch = useDispatch();

  const sortRequirementsMutation = useEnsisMutation(
    `/app/proposals/${proposalUid}/sort-requirements`,
    {
      showSuccessMessage: false,
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`, '/app/requirement-responses'],
      onSuccess: onClose
    }
  );

  const { data } = useEnsisQuery(
    '/app/requirement-responses',
    { queryParams: { proposal_uid: proposalUid } }
  );

  useEffect(() => {
    const formattedRequirementData = formatRequirementData(data?.items ?? []);
    if (sortRequirementsMutation.isSuccess) {
      dispatch(updateOutlineRequirements({ requirements: formattedRequirementData }));
      if (formattedRequirementData.some((req) => req.sectionUid === UNASSIGNED_SECTION_DATA.uid)) {
        showWarningNotification('One or more requirements could not be sorted with Autosort');
      }
    }
  }, [sortRequirementsMutation.isSuccess]);

  const handleSortRequirements = useCallback(() => {
    sortRequirementsMutation.mutate({});
  }, [sortRequirementsMutation]);

  const loader = (

    <Box ta='center' p={24}>
      <Title order={5} c='var(--mantine-color-darkPurple-5)'>Sorting requirements</Title>
      <Text c='var(--mantine-color-darkPurple-5)'>
        Please wait while Ensis is sorting your requirements into sections. This may take a few minutes.
      </Text>
      <CenteredLoader m={36} h={150} loaderProps={{ size: 150 }} />
    </Box>
  );

  if (sortRequirementsMutation.isPending) {
    return loader;
  };

  return (
    <ModalContent
      title='Autosort Remaining Requirements'
      onClose={onClose}
      primaryButton={{
        disabled: sortRequirementsMutation.isPending,
        label: 'Sort requirements',
        onClick: handleSortRequirements
      }}
    >
      <Text>
        Would you like to automatically sort your remaining requirements into sections?
      </Text>
      <Text mt={8}>
        {'Once sorted, your requirements will be placed in their respective sections within the \'Outline\' tab.'}
      </Text>
    </ModalContent>
  );
};

export default AutosortRequirements;
