import { type RoleData, type ResourceType, type ResourceFilterState } from '../types/miscTypes';

export const ADMIN_EMPTY_RESOURCES_STATE_TEXT = 'You haven\'t imported any resources.';

export const NON_ADMIN_EMPTY_RESOURCES_STATE_TEXT = 'Your organization\'s administrator has' +
  'not imported any resources. Contact your administrator or info@ensis.ai for support.';

export const EMPTY_RESOURCES_FILTER_STATE_TEXT = 'No matching resources found for current filter.';

export const EMPTY_EDITOR_CONTENT = '<p></p>';

export const MAX_AI_HINT_CHARACTERS = 600;

export const SECTION_TITLE_MAX_LENGTH_CHARS = 256;

export const UNASSIGNED_SECTION_DATA = { title: 'Unsorted', uid: 'null', slug: 'null', ordinal: 100 };

export const ROLE_COPY_MAP: Record<string, RoleData> = {
  ADMIN: {
    title: 'Admin',
    description: 'Full access to add and remove team members from the organization,' +
      ' manage member roles, and change resources'
  },
  FULL_MEMBER: {
    title: 'Member',
    description: 'Can create, edit, or delete any proposal within their organization.'
  }
};

export const RESOURCE_TYPE_TO_STRING_MAP: Record<ResourceType, string> = {
  WON_PROPOSAL: 'Won Proposal',
  LOST_PROPOSAL: 'Lost Proposal',
  RESUME: 'Resume',
  PROPOSAL_IN_PROGRESS: 'Proposal in Progress',
  DEFAULT: 'Miscellaneous Document'
};

export enum OutlineOutputFormatType {
  BULLET_POINTS = 'BULLET_POINTS',
  PARAGRAPH = 'PARAGRAPH'
}

export enum ProposalStatusType {
  INITIALIZING = 'INITIALIZING',
  SETUP = 'SETUP',
  PROCESSING = 'PROCESSING',
  AUTHORING = 'AUTHORING',
  IN_REVIEW = 'IN_REVIEW',
  FINALIZING = 'FINALIZING',
  DONE = 'DONE',
};

export const PROPOSAL_STATUS_TO_STRING_MAP: Record<ProposalStatusType, string> = {
  INITIALIZING: 'Initializing',
  SETUP: 'Setup',
  PROCESSING: 'Processing',
  AUTHORING: 'Authoring',
  IN_REVIEW: 'In Review',
  FINALIZING: 'Finalizing',
  DONE: 'Done'
};

export const PROPOSAL_STATUS_TYPES_PRE_AUTHORING = [ProposalStatusType.SETUP, ProposalStatusType.PROCESSING];

export const WIN_THEMES_CONTEXT_TEXT =
  'Add any additional information relevant to this proposal, ' +
  'such as win themes and differentiators';

export const WIN_THEMES_CONTEXT_HELPER_TEXT = 'Suggested inputs include win themes and differentiators, ' +
  'including what you will do to address this RFP and the customer’s pain points. ' +
  'Describe the benefits of choosing you over the competition. Ensis AI leverages ' +
  'these inputs to create a customized proposal for your organization, guaranteeing ' +
  'it distinguishes you from your competitors.';

export const DEFAULT_RESOURCE_FILTER_STATE: ResourceFilterState = {
  tags: [],
  resourceTypes: [],
  searchTerm: '',
  inUse: false
};
