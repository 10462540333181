import { useEffect, useMemo } from 'react';

import { type TableOptions, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { normalizeOrderingField } from '../utils/stringUtils';
import { useSorting } from './useOrdering';
import { usePagination, usePaginationParams } from './usePagination';
import useEnsisQuery, { type QueryConfig } from './useEnsisQuery';
import { getPageCount } from '../utils/tableUtils';
import type { BaseRow, SortDirection } from '../types/tableTypes';
import { type PaginatedRoute, type GetRequestQueryParamsType, type RouteWithGet } from '../types/apiTypes';

const DEFAULT_PAGE_SIZE = 9;

const getDBField = (columnToDBMap: Map<string, string>, field: string) => {
  const isDBField = [...columnToDBMap.values()].includes(field);
  return isDBField ? field : columnToDBMap.get(field) ?? '';
};

const formatOrderByField = (
  field: string,
  sortDirection: SortDirection,
  columnNameToDBFieldMap: Map<string, string>
) => {
  const orderByDBField = getDBField(columnNameToDBFieldMap, field);
  const normalizedOrderByDBField = normalizeOrderingField(orderByDBField, sortDirection);
  return normalizedOrderByDBField;
};

type ColumnInputType<T> = TableOptions<T>['columns'];

const usePaginatedTable = <RowType extends BaseRow>({
  defaultSortingField,
  route,
  formatData,
  columnData,
  columnNameToDBFieldMap,
  defaultSortDirection,
  queryParams,
  queryConfig,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  tableConfig,
  columnVisibility
}: {
  defaultSortingField: string
  route: PaginatedRoute
  formatData: (data: any) => RowType[]
  columnData: ColumnInputType<RowType>
  columnNameToDBFieldMap: Map<string, string>
  defaultSortDirection: SortDirection
  queryParams?: GetRequestQueryParamsType<RouteWithGet>
  queryConfig?: QueryConfig<RouteWithGet>
  defaultPageSize?: number
  tableConfig?: Partial<TableOptions<RowType>>
  columnVisibility?: Record<string, boolean>
}) => {
  const {
    parsedPageSize,
    parsedSortBy,
    parsedPageIndex,
    parsedSortDirection,
    updateSearchParams
  } = usePaginationParams();
  const {
    sorting,
    onSortingChange
  } = useSorting(
    parsedSortBy ?? defaultSortingField,
    parsedSortDirection as SortDirection ?? defaultSortDirection
  );
  const {
    onPaginationChange,
    pagination
  } = usePagination(parsedPageSize ?? defaultPageSize, parsedPageIndex ?? 0);

  const pageSize = parsedPageSize ?? defaultPageSize;
  const sortByField = sorting[0]?.id;
  const sortDirection = sorting[0]?.desc ? 'DESC' : 'ASC' as SortDirection;

  useEffect(() => {
    updateSearchParams(pagination.pageSize, sortByField, pagination.pageIndex, sortDirection);
  }, [pagination.pageSize, sortByField, pagination.pageIndex, sortDirection]);

  const { data, isLoading, isSuccess } = useEnsisQuery(
    route,
    {
      queryParams: {
        limit: pageSize,
        order_by: formatOrderByField(sortByField, sortDirection, columnNameToDBFieldMap),
        offset: pagination.pageIndex * pagination.pageSize,
        ...queryParams as object
      },
      ...queryConfig as object
    }
  );

  const formattedData = useMemo(
    () => formatData(data?.items ?? []),
    [data]
  );

  const rowCount = data?.total;

  const table = useReactTable({
    data: formattedData,
    columns: columnData,
    state: {
      pagination,
      sorting,
      columnVisibility: columnVisibility ?? {}
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange,
    onSortingChange,
    pageCount: getPageCount(data?.total, pageSize),
    manualPagination: true,
    manualSorting: true,
    ...tableConfig
  });

  return { table, isLoading, isSuccess, rowCount };
};

export default usePaginatedTable;
