/* eslint-disable max-len */
import { type Location } from 'react-router-dom';

import { type SortDirection, type CurrentUserRow } from '../types/tableTypes';
import { type Proposal, type OrganizationResourceTag, type WithoutPermissions, type EnsisUser } from '../types/apiTypes';
import { EMPTY_EDITOR_CONTENT, ROLE_COPY_MAP } from './constants';

export const getSplitLocation = (location: Location) => (
  location.pathname.split('/')
);

export const formatOrganizationTitle: (organizationName: string, pageName: string) => string =
  (organizationName: string,
    pageName: string) => {
    if (organizationName.slice(-1) === 's') {
      return `${organizationName}' ${pageName}`;
    }
    return `${organizationName}'s ${pageName}`;
  };

export const formatRole = (role: string) => {
  if (ROLE_COPY_MAP[role] !== undefined) {
    return ROLE_COPY_MAP[role].title;
  }
  return (role
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
    .join(' ')
  );
};

export const getProposalOwnerName: (proposal: Proposal) => string = (proposal) => {
  return `${proposal.owner?.first_name} ${proposal.owner?.last_name}`;
};

export const validateEmail: (email: string) => boolean = (email: string) => {
  const emailRegex = /^[\w-+.]+@([\w-]+\.)+[\w-]{2,6}$/;
  return emailRegex.test(email.trim());
};

export const getUserFullName: (user?: CurrentUserRow) => string = (user?: CurrentUserRow) => {
  if (user === undefined) {
    return '-';
  } else if (user.lastName === undefined && user.firstName === undefined) {
    return user.email;
  } else if (user?.lastName === '-' && user?.firstName === '-') {
    return user.email;
  }
  return `${user.firstName ?? ''} ${user.lastName ?? ''}`;
};

export const formatDateFromString: (date?: string) => string | null = (date?: string) => {
  if (date === undefined) {
    return null;
  } else {
    return new Date(date).toDateString().substring(4);
  }
};

export const getBasicWordCount: (selection: string) => number = (selection: string) => {
  const words = selection.match(/([\w']+)/g);
  return words?.length ?? 0;
};

export const formatTextForEditor: (content: string) => string = (content: string) => (
  content.replaceAll('\n', '<p/>')
);

export const isEmpty: (content: string) => boolean = (text: string) => (
  text.trim() === ''
);

/**
 * Given a field and a direction, return the normalized ordering field
 * used by the API to sort by the field
 *
 * @example normalizeOrderingField('name', 'DESC') returns '-name'
 * @example normalizeOrderingField('name', 'ASC') returns 'name'
 */
export const normalizeOrderingField = (field: string, direction: SortDirection) => {
  // Prevent returning '-' if field is empty
  if ((field ?? '') === '') {
    return field;
  }
  if (direction === 'DESC') {
    return `-${field}`;
  }
  return field;
};

const EMPTY_TEXT_BLOCK_COPY = 'Further details to add to this section? Add them here.';
const EMPTY_SECTION_TEXT_BLOCK_COPY = 'No requirements here. Use this space to craft this section of your proposal.';

export const getRequirementText = (requirementText: string, sectionIsEmpty: boolean) => {
  if (requirementText !== '') {
    return requirementText;
  } else if (!sectionIsEmpty) {
    return EMPTY_TEXT_BLOCK_COPY;
  }
  return EMPTY_SECTION_TEXT_BLOCK_COPY;
};

export const getSpacingString = (spacing: number): string => {
  if (spacing === 1) {
    return 'Single';
  } else if (spacing === 1.5) {
    return '1.5';
  } else if (spacing === 2) {
    return 'Double';
  } else {
    return '';
  }
};

export const getWordCount = (str: string): number => (
  str.split(' ').length
);

export const truncateText = (text: string, maxCharacters: number) => (
  text.length > maxCharacters ? `${text.substring(0, maxCharacters)}...` : text
);

export const cleanPDFText = (pdfText: string): string => (
  pdfText.replace('\x00', '')
);

export const editorContentIsEmpty = (content?: string | null): boolean => (
  content === '' || content == null || content === EMPTY_EDITOR_CONTENT
);

export const tagMatchesCaseInsensitive = (tags: OrganizationResourceTag[], tagText: string) => (
  tags.some((tag) => tag.text.toLowerCase() === tagText.toLowerCase())
);

const isNullishString = (str: string | null | undefined): boolean => (
  str === null || str === undefined || str === ''
);

export const getUserDisplayName = (user: WithoutPermissions<EnsisUser>) => {
  if (!isNullishString(user.first_name) || !isNullishString(user.last_name)) {
    return `${user.first_name} ${user.last_name}`;
  }
  return user.email;
};
