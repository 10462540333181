import { Box, Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useState } from 'react';
import type { Row } from '@tanstack/react-table';

import { CenteredLoader, Table, Pagination } from '../../components';
import { ImportOpportunityFile, DeleteOpportunityFile } from '../../components/Modals';
import useOpportunityFileTableData from '../../hooks/useOpportunityFileTableData';
import { defaultModalProps } from '../../utils/mantineUtils';
import type { OpportunityFileRow } from '../../types/tableTypes';
import TableEmptyState from '../../components/Table/TableEmptyState';

interface OpportunityFileCardProps {
  opportunityUid: string
}

const OpportunityFilesSection: React.FC<OpportunityFileCardProps> = (
  { opportunityUid }: OpportunityFileCardProps
) => {
  const [addFileOpened, addFileHandlers] = useDisclosure();
  const [deleteFileOpened, deleteFileHandlers] = useDisclosure();
  const [
    selectedRow,
    setSelectedRow
  ] = useState<OpportunityFileRow>();

  const handleReplaceOpportunityFile = useCallback((row: Row<OpportunityFileRow>) => {
    setSelectedRow(row.original);
    addFileHandlers.open();
  }, [addFileHandlers.open]);

  const handleClickAddFile = useCallback(() => {
    setSelectedRow(undefined);
    addFileHandlers.open();
  }, [addFileHandlers.open]);

  const handleClickDeleteFile = useCallback((row: Row<OpportunityFileRow>) => {
    setSelectedRow(row.original);
    deleteFileHandlers.open();
  }, [addFileHandlers.open]);

  const { table, isLoading } = useOpportunityFileTableData(
    opportunityUid,
    handleClickDeleteFile,
    handleReplaceOpportunityFile
  );

  const tableIsEmpty = table.getRowModel().rows.length === 0;

  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <Box mb={16}>
      <Modal opened={addFileOpened} {...defaultModalProps}>
        <ImportOpportunityFile
          onClose={addFileHandlers.close}
          opportunityUid={opportunityUid}
          opportunityFileUid={selectedRow?.uid}
          opportunityFileName={selectedRow?.name}
        />
      </Modal>
      <Modal opened={deleteFileOpened} {...defaultModalProps}>
        <DeleteOpportunityFile
          onClose={deleteFileHandlers.close}
          opportunityFileUid={selectedRow?.uid ?? ''}
          opportunityFileName={selectedRow?.name ?? ''}
        />
      </Modal>
      <Box w='100%' pr={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleClickAddFile} variant="outline">
          Import Opportunity File
        </Button>
      </Box>
      {tableIsEmpty
        ? <TableEmptyState text={'No opportunity files exist for this proposal.'} />
        : <>
          <Table table={table} />
          <Pagination table={table} />
        </>
      }
    </Box>
  );
};

export default OpportunityFilesSection;
