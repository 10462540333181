/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const SvgMagnifyingGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="#868E96"
      d="M4.333 0a4.333 4.333 0 0 1 3.294 7.153l.18.18h.526l3.334 3.334-1 1-3.334-3.334v-.526l-.18-.18A4.333 4.333 0 1 1 4.333 0m0 1.333c-1.666 0-3 1.334-3 3 0 1.667 1.334 3 3 3 1.667 0 3-1.333 3-3 0-1.666-1.333-3-3-3"
    />
  </svg>
);
export default SvgMagnifyingGlass;
