import { Text } from '@mantine/core';

import ModalContent from '../ModalContent';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import CenteredLoader from '../CenteredLoader';

interface Props {
  close: () => void
  onContinue: () => void
  proposalUid?: string
};

const MoveProposalToAuthoring: React.FC<Props> = ({ close, onContinue, proposalUid }: Props) => {
  const { data: requirementResponseData, isLoading: requirementResponseLoading } = useEnsisQuery(
    '/app/requirement-responses',
    {
      queryParams: { proposal_uid: proposalUid }
    }
  );
  const numberOfUnassignedRequirements = requirementResponseData?.items?.filter(
    (req) => req?.proposal_section === null
  ).length ?? 0;
  const numberOfSortedRequirements = requirementResponseData?.items?.filter(
    (req) => req?.proposal_section !== null
  ).length ?? 0;

  const noRequirementsSorted = numberOfSortedRequirements === 0;
  const noRequirementsSortedTitle = 'No Requirements Sorted';
  const noRequirementsSortedText = (
    <>
      <Text>
        {'You haven\'t added any requirements to your outline. Are you sure you want to continue?'}
      </Text>
      <Text mt={24}>
        {'Note: You can always add these requirements later by going to: Proposal Management > Outline'}
      </Text>
    </>
  );

  const unassignedRequirementsRemaining = numberOfUnassignedRequirements > 0;
  const unassignedRequirementsRemainingTitle = `${numberOfUnassignedRequirements} Unsorted Requirements`;
  const unassignedRequirementsRemainingText = (
    <>
      <Text>
        {`You have ${numberOfUnassignedRequirements} requirements that weren't added to your outline.
        Are you sure you want to continue?`}
      </Text>
      <Text mt={24}>
        {'Note: You can always add these requirements later by going to: Proposal Management > Outline'}
      </Text>
    </>
  );

  const defaultTitle = 'Ready to Start Authoring?';
  const defaultText = (<Text>
    Once you move to authoring, you can modify your outline from the Proposal Management page.
  </Text>);

  const modalTitle = noRequirementsSorted
    ? noRequirementsSortedTitle
    : (unassignedRequirementsRemaining ? unassignedRequirementsRemainingTitle : defaultTitle);
  const modalText = noRequirementsSorted
    ? noRequirementsSortedText
    : (unassignedRequirementsRemaining ? unassignedRequirementsRemainingText : defaultText);

  if (requirementResponseLoading) {
    return <CenteredLoader />;
  }

  return (<ModalContent
    title={modalTitle}
    primaryButton={{
      label: 'Continue to Authoring',
      onClick: onContinue
    }}
    secondaryButton={{
      label: 'Go Back',
      onClick: close
    }}
    onClose={close}
  >
    {modalText}
  </ModalContent>);
};

export default MoveProposalToAuthoring;
