import { Table as MantineTable, Group } from '@mantine/core';
import { type Table as TanstackTable, flexRender, type Row } from '@tanstack/react-table';
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DraggableProvided,
  type OnDragEndResponder
} from '@hello-pangea/dnd';

import simpleClasses from './SimpleTable.module.css';
import mainClasses from './MainTable.module.css';
import withTagsClasses from './TableWithTags.module.css';
import tagsRowClasses from './TableTagsRow.module.css';

import { ArrowDropdown, ArrowUp } from '../../icons';
import { type TableRows } from '../../types/tableTypes';

interface Props<T> {
  table: TanstackTable<T>
  onRowClick?: (row: Row<T>) => void
  tableStyle?: 'simple' | 'main' | 'withTags'
  handleDragEnd?: OnDragEndResponder
};

interface RowProps<T> {
  provided?: DraggableProvided
  row: Row<T>
  onRowClick?: (row: Row<T>) => void
  className: string
}

const TableHead = <T extends TableRows>(props: Props<T>) => {
  const { table } = props;
  return (
    <MantineTable.Thead>
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <MantineTable.Th
              key={header.id}
              style={{
                color: (header.column.getIsSorted() === false)
                  ? 'var(--mantine-color-darkPurple-9)'
                  : 'var(--mantine-color-lightPurple-5)',
                w: `${header.getSize()}%`,
                cursor: (header.column.getCanSort() ? 'pointer' : '')
              }}
            >
              {header.isPlaceholder
                ? null
                : (
                  <Group
                    gap={2}
                    {...{
                      onClick: header.column.getToggleSortingHandler()
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}{{
                      asc: <ArrowUp />,
                      desc: <ArrowDropdown />
                    }[header.column.getIsSorted() as string] ?? null}
                  </Group>
                  )}
            </MantineTable.Th>
          ))}
        </tr>
      ))}
    </MantineTable.Thead>
  );
};

const TableRow = <T extends TableRows>(props: RowProps<T>) => {
  const { provided, row, onRowClick, className } = props;
  const bottomCellColumns = row.getAllCells().filter(cell => cell.column.id.includes('bottom'));
  const regularCells = row.getVisibleCells().filter(cell => !cell.column.id.includes('bottom'));
  return (
    <>
      <MantineTable.Tr
        ref={provided?.innerRef}
        {...provided?.draggableProps}
        className={className}
        onClick={() => { onRowClick !== undefined && onRowClick(row); }}
      >
        {regularCells.map(cell => {
          if (cell.column.id === 'dnd') {
            return (
              <MantineTable.Td
                {...provided?.dragHandleProps}
                key={cell.id}
                style={{ width: `${cell.column.getSize()}%` }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </MantineTable.Td>
            );
          }
          return (
            <MantineTable.Td
              key={cell.id}
              style={{ width: `${cell.column.getSize()}%` }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </MantineTable.Td>
          );
        })}
      </MantineTable.Tr>
      {bottomCellColumns !== undefined && bottomCellColumns.length !== 0 && (
        <MantineTable.Tr classNames={tagsRowClasses}>
          {bottomCellColumns.map((column, index) => {
            const BottomCellComponent = column?.column.columnDef.cell;
            const colSpan = index === (bottomCellColumns.length - 1) ? (regularCells.length - index) : undefined;
            return (BottomCellComponent !== undefined && (
              <MantineTable.Td key={column.id} colSpan={colSpan} classNames={{ td: tagsRowClasses.td }}>
                <BottomCellComponent {...column.getContext()} />
              </MantineTable.Td>
            )
            );
          })}
        </MantineTable.Tr>
      )}
    </>
  );
};

const Table = <T extends TableRows>(props: Props<T>) => {
  const { table, tableStyle, onRowClick, handleDragEnd } = props;
  const getClasses = () => {
    if (tableStyle === 'simple') {
      return simpleClasses;
    }
    if (tableStyle === 'withTags') {
      return withTagsClasses;
    }
    return mainClasses;
  };
  const classes = getClasses();

  if (handleDragEnd !== undefined) {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <MantineTable classNames={{
          table: classes.table,
          td: classes.td,
          th: classes.th
        }}
        >
          <TableHead table={table} />
          <Droppable droppableId="dnd-table" direction="vertical">
            {(provided) => (
              <MantineTable.Tbody
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {table.getRowModel().rows.map((row, index) => (
                  <Draggable index={index} key={row.original.uid} draggableId={row.original.uid}>
                    {(provided) => (
                      <TableRow
                        onRowClick={onRowClick}
                        row={row}
                        provided={provided}
                        className={row.original.isClickable ? classes.trClickable : classes.tr}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </MantineTable.Tbody>
            )}
          </Droppable>
        </MantineTable>
      </DragDropContext>
    );
  }

  return (
    <MantineTable
      classNames={{
        table: classes.table,
        td: classes.td,
        th: classes.th
      }}
    >
      <TableHead table={table} />
      <MantineTable.Tbody>
        {table.getRowModel().rows.map((row) => (
          <TableRow
            key={row.original.uid}
            onRowClick={onRowClick}
            row={row}
            className={row.original.isClickable ? classes.trClickable : classes.tr}
          />
        ))}
      </MantineTable.Tbody>
    </MantineTable>
  );
};

export default Table;
