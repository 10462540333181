import { LoadingOverlay, Radio, Stack } from '@mantine/core';

import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import ModalContent from '../../ModalContent';
import { getUserFullName } from '../../../utils/stringUtils';
import classes from './EditUserRole.module.css';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { type CurrentUserRow } from '../../../types/tableTypes';
import { ROLE_COPY_MAP } from '../../../utils/constants';

interface Props {
  user: CurrentUserRow
  close: () => void
}
const EditUserRole: React.FC<Props> = (props: Props) => {
  const { close, user } = props;
  const [newUserRole, setNewUserRole] = useState(user.role);
  const { organization } = useParams();

  const fullName = getUserFullName(user);

  const editRoleMutation = useEnsisMutation(
    `/app/users/${user.uid}/update-org-role`,
    {
      contentType: 'application/json',
      requestType: 'patch',
      successMessage: 'User role updated',
      onSuccess: close,
      queryKeysToInvalidate: ['/app/users']
    }
  );

  const handleChangeUserRole = useCallback(() => {
    editRoleMutation.mutate({ organization_slug: organization ?? '', role: newUserRole });
  }, [newUserRole, editRoleMutation]);

  return (
    <ModalContent
      onClose={close}
      title= {`Choose a role for ${fullName}`}
      secondaryButton={{
        label: 'Cancel',
        onClick: close
      }}
      primaryButton={{
        label: 'Save',
        onClick: handleChangeUserRole,
        disabled: newUserRole === user.role
      }}
    >
      <LoadingOverlay zIndex={1000} visible={editRoleMutation.isPending}/>
      <Radio.Group
        value={newUserRole}
        onChange={setNewUserRole}
      >
        <Stack gap='xl'>
          {Object.keys(ROLE_COPY_MAP).map(
            (role) =>
            <Radio
              classNames={{ description: classes.description, label: classes.label }}
              key={role}
              value={role}
              label={ROLE_COPY_MAP[role].title}
              description={ROLE_COPY_MAP[role].description}
            />
          )}
        </Stack>
      </Radio.Group>
    </ModalContent>
  );
};

export default EditUserRole;
