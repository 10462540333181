import { Box } from '@mantine/core';

import ReactMarkdown from 'react-markdown';

import { type ConversationMessage, type WithoutPermissions } from '../../types/apiTypes';

interface Props {
  message: WithoutPermissions<ConversationMessage>
}

const HumanChatMessage: React.FC<Props> = (props: Props) => {
  const { message } = props;
  return (
    <div
      style={{
        fontSize: '14px',
        marginRight: '8px',
        padding: '1px 8px 8px 8px',
        justifySelf: 'end',
        backgroundColor: 'var(--mantine-color-lightPurple-0)',
        borderRadius: '8px 0px 8px 8px',
        maxWidth: '80%'
      }}>
      <ReactMarkdown
        components={{
          p: ({ children }) => <Box style={{ marginTop: 8 }}>{children}</Box>,
          ol: ({ children }) => <ol style={{ margin: 0 }}> {children} </ol>
        }}
      >
        {message.content}
      </ReactMarkdown>
    </div>
  );
};

export default HumanChatMessage;
