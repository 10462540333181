import { Group } from '@mantine/core';
import { useCallback, useState } from 'react';

import ResourceFilter from '../ResourceFilter';
import SearchBar from '../SearchBar';
import type { ResourceFilterStateNoSearch, ResourceFilterState } from '../../types/miscTypes';
import { type OrganizationResourceTag } from '../../types/apiTypes';

interface Props {
  availableTags: OrganizationResourceTag[]
  onUpdateFilters: (filterUpdates: ResourceFilterState) => void
  filters: ResourceFilterState
  canFilterByInUse?: boolean
  disabled?: boolean
}

const ResourceFilterGroup: React.FC<Props> = ({
  onUpdateFilters,
  availableTags,
  filters,
  canFilterByInUse = false,
  disabled = false
}: Props) => {
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');

  const { searchTerm, ...otherFilters } = filters;

  const handleUpdateSearchTerm = useCallback(() => {
    onUpdateFilters({ ...filters, searchTerm: currentSearchTerm });
  }, [currentSearchTerm, onUpdateFilters, filters]);

  const handleClearSearchTerm = useCallback(() => {
    setCurrentSearchTerm('');
    onUpdateFilters({ ...filters, searchTerm: '' });
  }, [onUpdateFilters, filters]);

  const handleUpdateResourceFilters = useCallback((newFilters: ResourceFilterStateNoSearch) => {
    onUpdateFilters({ searchTerm, ...newFilters });
  }, [onUpdateFilters, filters]);

  return (
    <Group align='center'>
      <SearchBar
        disabled={disabled}
        value={currentSearchTerm}
        onChange={setCurrentSearchTerm}
        onSubmit={handleUpdateSearchTerm}
        onClear={handleClearSearchTerm}
      />
      <ResourceFilter
        availableTags={availableTags}
        filters={otherFilters}
        onUpdateFilters={handleUpdateResourceFilters}
        canFilterByInUse={canFilterByInUse}
      />
    </Group>
  );
};

export default ResourceFilterGroup;
