import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';

import { MantineProvider } from '@mantine/core';
import * as Sentry from '@sentry/react';

import BaseApp from './BaseApp';
import reportWebVitals from './reportWebVitals';

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/tiptap/styles.css';

import theme from './theme';
import Settings from './Pages/Settings/Settings';
import Dashboard from './Pages/Dashboard/Dashboard';
import Resources from './Pages/Resources/Resources';
import ManageProposalPage from './Pages/ManageProposal/ManageProposalPage';
import AcceptInvitePage from './Pages/AcceptInvitePage';
import { DEPLOYMENT_ENV, SENTRY_DSN } from './Config';
import OrganizationApp from './OrganizationApp';
import EditorPage from './Pages/Editor/EditorPage';
import SectionEditor from './Pages/Editor/SectionEditor';
import App from './App';
import CreateProposalWizard from './Pages/CreateProposalWizard/CreateProposalWizard';
import SetupProposal from './Pages/SetupProposal/SetupProposal';

// Set QA favicon if not in prod
const favicon = document.getElementById('favicon') as HTMLLinkElement;
if (DEPLOYMENT_ENV !== 'prod' && favicon != null) {
  favicon.href = process.env.PUBLIC_URL + '/favicon-non-prod.ico';
}

let createBrowserRouterFn = createBrowserRouter;

// Initialize Sentry and Google Analytics if not running locally
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: DEPLOYMENT_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/api*.ensis\.ai\/app/],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1
  });

  createBrowserRouterFn = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  ReactGA.initialize('G-GR1808DQLM');
  ReactGA.gtag('set', 'user_properties', {
    environment: DEPLOYMENT_ENV
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as Element
);

const router = createBrowserRouterFn([
  {
    path: '/',
    element: <BaseApp />,
    children: [
      {
        element: <App />,
        path: '/',
        children: [{
          path: '/org/:organization',
          element: <OrganizationApp />,
          children: [
            {
              path: '/org/:organization/settings/:section?',
              element: <Settings />
            },
            {
              path: '/org/:organization/proposals',
              element: <Dashboard />
            },
            {
              path: '/org/:organization/resources',
              element: <Resources />
            },
            {
              path: '/org/:organization/proposals/:proposalUid',
              element: <EditorPage />,
              children: [
                {
                  path: '/org/:organization/proposals/:proposalUid/edit/:sectionUid',
                  element: <SectionEditor />
                },
                {
                  path: '/org/:organization/proposals/:proposalUid/manage/:proposalManagementTab?',
                  element: <ManageProposalPage />
                }
              ]
            },
            {
              path: '/org/:organization/proposals/create-proposal/:proposalUid?',
              element: <CreateProposalWizard />
            },
            {
              path: '/org/:organization/proposals/setup-proposal/:proposalUid',
              element: <SetupProposal />
            }
          ]
        }]
      },
      {
        path: '/invite/:inviteUid',
        element: <AcceptInvitePage />
      }
    ]
  }
]);

root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <RouterProvider router={router} />
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
