import { useNavigate, useParams } from 'react-router-dom';

import { useCallback, useEffect, useState } from 'react';

import { Stepper, Box, Group, ActionIcon, LoadingOverlay } from '@mantine/core';

import CreateProposal from './CreateProposal';
import SelectResources from './SelectResources';
import SelectRequirementPages from './SelectRequirementPages';

import classes from '../../components/Header/Header.module.css';
import { BackArrow } from '../../icons';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { ProposalStatusType } from '../../utils/constants';
import NoFilesSuccessScreen from './NoFilesSuccessScreen';

const CreateProposalWizard: React.FC = () => {
  const { proposalUid, organization } = useParams();
  const { data: proposal, isLoading: proposalLoading } = useEnsisQuery(
    `/app/proposals/${proposalUid}/data`, {
      enabled: proposalUid !== undefined
    }
  );

  const noOpportunityFilesUploaded = proposal?.opportunity?.files?.length === 0;

  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const updateProposalStatusMutation = useEnsisMutation(`/app/proposals/${proposalUid}/data`,
    {
      requestType: 'patch',
      showSuccessMessage: false,
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    });

  const generateOutlineMutation = useEnsisMutation(
    `/app/proposals/${proposalUid}/generate-outline`, {
      showSuccessMessage: false,
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    }
  );

  const extractRequirementsMutation = useEnsisMutation(
    `/app/opportunities/${proposal?.opportunity?.uid ?? ''}/extract-requirements`, {
      showSuccessMessage: false,
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`, '/app/requirement-responses']
    }
  );

  // route appropriately after files have loaded
  useEffect(() => {
    if (proposalUid !== undefined && !proposalLoading) {
      if (noOpportunityFilesUploaded) {
        setCurrentStep(2);
      } else {
        setCurrentStep(1);
      }
    }
  }, [proposalLoading]);

  const header = (
    <header>
      <Group justify='space-between' className={classes.headerRoot}>
        <ActionIcon
          variant='subtle'
          c='var(--mantine-color-darkPurple-9)'
          onClick={() => { navigate('/'); }}
        >
          <BackArrow />
        </ActionIcon>
        <Stepper active={currentStep}>
          <Stepper.Step label="Step 1" description="Upload files" />
          <Stepper.Step label="Step 2" description="Page ranges" />
          <Stepper.Step label="Step 3" description="Select resources" />
        </Stepper>
        <Box>
        </Box>
      </Group>
    </header >);

  const handleGoToProposalManagement = useCallback(() => {
    history.replaceState({}, '', `/org/${organization}/proposals`);
    navigate(`/org/${organization}/proposals/${proposalUid}/manage/resources`);
  }, [proposalUid, organization]);

  const handleOnCreationDone = useCallback(async () => {
    if (noOpportunityFilesUploaded) {
      await generateOutlineMutation.mutateAsync({}, {});
      await updateProposalStatusMutation.mutateAsync({ status: ProposalStatusType.AUTHORING });
      setCurrentStep(3);
    } else {
      extractRequirementsMutation.mutate({ proposal_uid: proposalUid ?? null });
      navigate(`/org/${organization}/proposals/setup-proposal/${proposalUid}`);
    }
  }, [extractRequirementsMutation, updateProposalStatusMutation, noOpportunityFilesUploaded]);

  if (proposalUid !== undefined && (proposalLoading || proposal === undefined)) {
    return <Box w='100%'>
      {header}
      <Box>
        <LoadingOverlay />
      </Box>
    </Box>;
  }

  const steps = [
    {
      component: <CreateProposal onDone={() => { setCurrentStep(noOpportunityFilesUploaded ? 2 : 1); }} />
    },
    {
      component: <SelectRequirementPages
        proposalUid={proposalUid ?? ''}
        onDone={() => { setCurrentStep(2); }}
      />
    },
    {
      component: <SelectResources
        onClickNext={() => { void handleOnCreationDone(); }}
        onClickPrevious={noOpportunityFilesUploaded ? undefined : () => { setCurrentStep(1); }}
        organizationSlug={organization ?? ''}
        proposal={proposal}
        loading={generateOutlineMutation.isPending}
      />
    },
    {
      component: <NoFilesSuccessScreen
        onGoToProposalManagement={handleGoToProposalManagement}
      />
    }
  ];

  return (
    <Box w='100%'>
      {header}
      <Box>
        {steps[currentStep].component}
      </Box>
    </Box>
  );
};
export default CreateProposalWizard;
