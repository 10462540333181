import { useCallback } from 'react';
import { TextInput, ActionIcon } from '@mantine/core';

import classes from './SearchBar.module.css';
import { MagnifyingGlass, Close } from '../../icons';

export interface Props {
  value: string
  onChange: (value: string) => void
  onSubmit: () => void
  onClear: () => void
  disabled?: boolean
  placeholder?: string
}

const SearchBar: React.FC<Props> = ({
  value,
  onChange,
  onClear,
  onSubmit,
  disabled = false,
  placeholder = 'Enter search term'
}: Props) => {
  const handleKeyDown = useCallback((event: any) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  }, [onSubmit]);

  const isEmpty = value === '';
  const rightSection = isEmpty
    ? undefined
    : (
        <ActionIcon
          variant='subtle'
          className={classes.searchBarRightIcon}
          disabled={disabled}
          onClick={onClear}
        >
          <Close width={12} height={12} />
        </ActionIcon>
      );

  return (
    <TextInput
      w={350}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={(e) => { onChange(e.target.value); }}
      rightSection={rightSection}
      leftSection={<MagnifyingGlass />}
      onKeyDown={handleKeyDown}
    />
  );
};

export default SearchBar;
