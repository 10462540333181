import { Text, Stack, Group, ActionIcon, ScrollArea, Select, Divider } from '@mantine/core';
import { Dropzone as MantineDropzone, MIME_TYPES } from '@mantine/dropzone';
import '@mantine/dropzone/styles.css';

import { useMemo } from 'react';

import { Trash, FileUploadIcon } from '../../icons';
import { RESOURCE_TYPE_TO_STRING_MAP } from '../../utils/constants';
import { type FileWithMetadata } from '../Forms/UploadResources/UploadResources';
import { type ResourceType } from '../../types/miscTypes';
import classes from './Dropzone.module.css';

const DEFAULT_ACCEPTED_FILE_TYPES = [
  MIME_TYPES.pdf,
  MIME_TYPES.doc,
  MIME_TYPES.docx,
  MIME_TYPES.xlsx,
  MIME_TYPES.xls,
  MIME_TYPES.ppt,
  MIME_TYPES.pptx
];

interface FileInformationProps {
  file: FileWithMetadata
  handleTrashClick: () => void
  setResourceType: (resourceType: ResourceType) => void
  showResourceTypeDropdown: boolean
}

const getFormattedFileSize = (file: File) => {
  const sizeInBytes = file.size;
  if (sizeInBytes < 1000000) {
    return `${Math.round(sizeInBytes / 1000)}kb`;
  }
  return `${Math.round(sizeInBytes / 1000000)}mb`;
};

const UploadedFileInformation: React.FC<FileInformationProps> = (props: FileInformationProps) => {
  return (
    <Group justify='space-between' mt={16} mb={16} pr={5}>
      <Group justify='flex-start' gap='md'>
        <FileUploadIcon color={'var(--mantine-color-lightPurple-5)'} />
        <Stack gap={0} maw={props.showResourceTypeDropdown ? 260 : 360}>
          <Text truncate='end'>
            {props.file.fileObject.name}
          </Text>
          <Text size='xs' c='var(--mantine-color-lightPurple-3)'>
            {`${getFormattedFileSize(props.file.fileObject)} • Complete`}
          </Text>
        </Stack>
      </Group>
      <Group>
        {props.showResourceTypeDropdown && <Select
          label='Resource Type'
          pb={25}
          w={150}
          radius={4}
          value={props.file.resourceType}
          onChange={(_value) => { props.setResourceType(_value as ResourceType); }}
          data={Object.keys(RESOURCE_TYPE_TO_STRING_MAP).map((type) => {
            return {
              value: type,
              label: RESOURCE_TYPE_TO_STRING_MAP[type as ResourceType]
            };
          })}
        />}
        <ActionIcon variant='subtle' onClick={() => { props.handleTrashClick(); }}>
          <Trash color={'var(--mantine-color-grey)'} />
        </ActionIcon>
      </Group>
    </Group>
  );
};

interface DropzoneProps {
  files: FileWithMetadata[]
  setFiles: (updatedFiles: FileWithMetadata[]) => void
  instructionText: string
  showResourceTypeDropdown?: boolean
  acceptedFileTypes?: string[]
  maxFiles?: number
  multiple?: boolean
  disabled?: boolean
}
const Dropzone: React.FC<DropzoneProps> = (props: DropzoneProps) => {
  const {
    files,
    setFiles,
    instructionText,
    acceptedFileTypes,
    maxFiles,
    multiple = true,
    showResourceTypeDropdown = false,
    disabled = false
  } = props;

  const removeFile = (index: number) => {
    setFiles(files.filter((e, i) => i !== index));
  };

  const filePaths = useMemo(() => files?.map(file => file.fileObject.path), [files]);
  const updateFileMetadata = (index: number, resourceType: ResourceType) => {
    const updatedFileList = [...files];
    updatedFileList[index].resourceType = resourceType;
    setFiles(updatedFileList);
  };
  const dropzoneDisabled = (maxFiles !== undefined && files.length === maxFiles) || disabled;

  return (
    <>
      <MantineDropzone
        className={dropzoneDisabled ? classes.disabledDropzone : classes.dropzone}
        accept={acceptedFileTypes ?? DEFAULT_ACCEPTED_FILE_TYPES}
        onDrop={(droppedFiles) => {
          if (!dropzoneDisabled) {
            const newFiles = droppedFiles.filter((file) => !filePaths.includes(file.path)).map((file) => {
              const fileObj: FileWithMetadata = {
                fileObject: file,
                resourceType: undefined
              };
              return fileObj;
            });
            setFiles(files.concat(newFiles));
          }
        }}
        onReject={(files) => { console.log('rejected files', files); }}
        maxFiles={maxFiles}
        disabled={dropzoneDisabled}
        multiple={multiple}
      >
        <Stack pt={24} align='center' gap='xs'>
          <FileUploadIcon color={'var(--mantine-color-lightPurple-5)'} />
          <Text ta={'center'}> {instructionText} </Text>
          <Text size="sm" c='var(--mantine-color-lightPurple-3)' inline mt={7}>
            PDF, DOC, XLS, or PPT
          </Text>
        </Stack>
      </MantineDropzone>
      <ScrollArea.Autosize type='auto' mah={300}>
        {files.map((file, index) => (
          <>
            <UploadedFileInformation
              showResourceTypeDropdown={showResourceTypeDropdown}
              key={+index}
              file={file}
              setResourceType={(resourceType: ResourceType) => { updateFileMetadata(index, resourceType); }}
              handleTrashClick={() => { removeFile(index); }}
            />
            {index !== files.length - 1 && <Divider />}
          </>
        ))}
      </ScrollArea.Autosize >
    </>
  );
};

export default Dropzone;
