import { Stack, Center, Button, Text } from '@mantine/core';

import { type ButtonProps } from '../ModalContent/ModalContent';

export interface TableEmptyStateProps {
  text: string
  actionButton?: ButtonProps
}

const TableEmptyState: React.FC<TableEmptyStateProps> = (props: TableEmptyStateProps) => {
  const { text, actionButton } = props;
  return (
    <Center h={224} mt={16} p={16} style={{ borderRadius: 4 }} bg='var(--mantine-color-greyPurple-1)'>
      <Stack align="center">
        <Text maw={545}>
          {text}
        </Text>
        {actionButton !== undefined &&
          <Button
            miw={150}
            bg={actionButton.color ?? 'var(--mantine-color-red-5)'}
            onClick={actionButton.onClick}
          >
            {actionButton.label}
          </Button>
        }
      </Stack>
    </Center>
  );
};

export default TableEmptyState;
