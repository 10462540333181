import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// map from RequirementResponseUid to new content
type EditorState = Record<string, string | undefined>;

interface UpdatePayload {
  uid: string
  content: string
  originalContent?: string
}

const initialState: EditorState = {};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    updateText (state, action: PayloadAction<UpdatePayload>) {
      const updatedContent = action.payload.originalContent === action.payload.content
        ? undefined
        : action.payload.content;
      state[action.payload.uid] = updatedContent;
    },
    resetUpdatedTexts (state, action: PayloadAction<string[]>) {
      action.payload.forEach((uid) => {
        state[uid] = undefined;
      });
    },
    resetText (state) {
      Object.keys(state).forEach((sectionUid) => {
        state[sectionUid] = undefined;
      });
    }
  }
});

export const { updateText, resetText, resetUpdatedTexts } = editorSlice.actions;
export default editorSlice.reducer;
