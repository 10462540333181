import { Text } from '@mantine/core';

import ModalContent from '../ModalContent';

interface Props {
  onClose: () => void
  onGenerateAll: () => void
}
/* eslint-disable max-len */
const WARNING_TEXT = 'You have unsaved edits to some responses. If you continue, these edits will be lost. To keep your changes, save first, then click "Generate all" again.';

const GenerateAllWarning: React.FC<Props> = (props: Props) => {
  const { onClose, onGenerateAll } = props;
  return (
    <ModalContent
      title='Generate all'
      onClose={onClose}
      primaryButton={{
        label: 'Generate All',
        onClick: () => { onGenerateAll(); onClose(); }
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose
      }}
    >
      <Text>
        {WARNING_TEXT}
      </Text>
    </ModalContent>
  );
};

export default GenerateAllWarning;
