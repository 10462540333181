import { Box, Button, Center, Text, Title, Stack } from '@mantine/core';
import Lottie from 'lottie-react';

import { checklistAnimation } from '../../assets/lottie';

interface Props {
  onGoToProposalManagement: () => void
}

const NoFilesSuccessScreen: React.FC<Props> = (props: Props) => {
  const { onGoToProposalManagement } = props;
  return <Box w='100%' p='24 16 16 16' h='calc(100vh - 110px)'>
    <Box ta='start'>
      <Title order={6}> Setup Complete </Title>
      <Text mih={50}>
        {`Continue in Proposal Management to adjust resources or refine win themes.
        Or, switch to Microsoft Word to select this proposal and keep working on it there.`}
      </Text>
    </Box>
    <Center h={'350px'}>
      <Stack>
        <Lottie animationData={checklistAnimation} style={{ margin: 0, padding: 0, height: 250 }} />
        <Button onClick={onGoToProposalManagement} variant='outline'>
          Go to Proposal Management
        </Button>
      </Stack>
    </Center>
  </Box >;
};

export default NoFilesSuccessScreen;
