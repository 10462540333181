import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Group, Stack, Tabs, Text, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import useEnsisQuery from '../../hooks/useEnsisQuery';
import { CenteredLoader, SelectProposalResources, SelectResponseFormat } from '../../components';
import { DeleteProposal } from '../../components/Modals';
import SelectProposalSpacing from '../../components/SelectProposalSpacing';
import { formatDateFromString } from '../../utils/stringUtils';
import OpportunityFilesSection from './OpportunityFilesSection';
import ProposalOutlineWithDocumentViewer from '../../components/ProposalOutlineWithDocumentViewer';
import ProposalFactsSection from './ProposalFactsSection';
import { AdditionalProposalInfo } from './AdditionalProposalInfo';
import { formatSectionsMetadata, hasPermissionsForObject } from '../../utils/apiUtils';
import { defaultModalProps } from '../../utils/mantineUtils';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { getProposalManagementRoute } from '../../utils/routerUtils';

const ManageProposalPage: React.FC = () => {
  const { organization, proposalUid, proposalManagementTab = 'outline' } = useParams();
  const navigate = useNavigate();
  const [deleteProposalOpened, deleteProposalHandlers] = useDisclosure();
  const { data, isLoading } = useEnsisQuery(`/app/proposals/${proposalUid}/data`);
  const opportunityUid = data?.opportunity.uid;
  const referenceUids = data?.references?.map((resource) => resource.uid) ?? [];

  const sections = formatSectionsMetadata(data?.sections) ?? [];

  const editProposalResources = useEnsisMutation(`/app/proposals/${proposalUid}/data`, {
    requestType: 'patch',
    successMessage: 'Resources updated',
    showSuccessMessage: false,
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
  });

  const handleUpdateReferences = useCallback((uids: string[]) => {
    editProposalResources.mutate({
      reference_uids: uids
    });
  }, [editProposalResources.mutate]);

  if (isLoading || data === undefined) {
    return <CenteredLoader />;
  }

  const canDeleteProposal = hasPermissionsForObject(data, 'delete');
  const proposalManagementRoute = getProposalManagementRoute(organization, proposalUid);

  return (
    <Box>
      <Modal opened={deleteProposalOpened} {...defaultModalProps}>
        <DeleteProposal
          close={deleteProposalHandlers.close}
          proposalName={data.name ?? ''}
          proposalUid={data.uid ?? ''}
        />
      </Modal>
      <Box w={'100vw'}>
        <Group
          justify='flex-start'
          p={16}
          gap={32}
          bg='var(--mantine-color-greyPurple-0)'
        >
          <SelectResponseFormat proposal={data} />
          <SelectProposalSpacing
            proposalUid={data?.uid ?? ''}
            initialSpacing={data?.spacing ?? 1}
          />
          <Stack gap={8}>
            <Group style={{ alignSelf: 'flex-start' }}>
              <Text fz='sm' ta='start' fw={600}> Date Created </Text>
            </Group>
            <Text> {formatDateFromString(data?.created_at ?? undefined) ?? 'Date created unknown'} </Text>
          </Stack>
          <Stack gap={8}>
            <Text fz='sm' ta='start' fw={600}> Last Edited </Text>
            <Text> {formatDateFromString(data?.created_at ?? undefined) ?? 'Last edited unknown'} </Text>
          </Stack>
          {
            canDeleteProposal && (
              <Box style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button
                  variant='subtle'
                  onClick={deleteProposalHandlers.open}
                >
                  Delete Proposal
                </Button>
              </Box>
            )
          }
        </Group>
      </Box>
      <Tabs
        defaultValue='outline'
        value={proposalManagementTab}
        onChange={(value) => {
          navigate(`${proposalManagementRoute}/${value}`, { replace: true });
        }}
      >
        <Tabs.List mih={46}>
          <Tabs.Tab value='opportunityFiles'>
            Opportunity Files
          </Tabs.Tab>
          <Tabs.Tab value='resources'>
            Resources
          </Tabs.Tab>
          <Tabs.Tab value='outline'>
            Outline
          </Tabs.Tab>
          <Tabs.Tab value='facts'>
            Facts
          </Tabs.Tab>
          <Tabs.Tab value='additionalInfo'>
            Win Themes / Differentiators
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='opportunityFiles' p={16}>
          <OpportunityFilesSection opportunityUid={opportunityUid ?? ''} />
        </Tabs.Panel>
        <Tabs.Panel value='resources' p={16}>
          <SelectProposalResources
            tableContainerStyle={{ height: 'calc(100vh - 400px)' }}
            referenceUids={referenceUids}
            onUpdateReferences={handleUpdateReferences}
            allowImport
          />
        </Tabs.Panel>
        <Tabs.Panel value='outline' p={16}>
          <ProposalOutlineWithDocumentViewer
            proposal={data}
            sections={sections}
            requirementRemovalType='UNASSIGN'
            style={{ height: 'calc(100vh - 300px)' }}
          />
        </Tabs.Panel>
        <Tabs.Panel value='facts' p={16}>
          <ProposalFactsSection />
        </Tabs.Panel>
        <Tabs.Panel value='additionalInfo' p={16}>
          <AdditionalProposalInfo
            additionalContext={data?.additional_context}
            tabIsFocused={proposalManagementTab === 'additionalInfo'}
          />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default ManageProposalPage;
